import { useState, useEffect,useContext } from 'react';
import Image from 'next/image';
import Link from "next/link";
import { CartContext } from "../../contexts/CartContext";
import { setImageSrc } from '../../utils/config';

/**
 * Snackbar component for displaying notifications related to cart actions.
 * 
 * This component shows a snackbar notification when a product is added to the cart.
 * It displays a message and provides links to view the cart or proceed to checkout.
 * 
 * @param {Object} product - The product related to the notification.
 * @param {boolean} isOpen - Flag to control the visibility of the snackbar.
 * @param {Function} setIsOpen - Function to update the isOpen state.
 */
export const Snackbar = ({ product, isOpen, setIsOpen }) => {
  const [exit, setExit] = useState(false); // State for exit animation
  const { cart, addToCart, updateQuantity, isSnackbarVisible, cartResponse } = useContext(CartContext);

  // Effect to handle auto-closing of the snackbar
  useEffect(() => {
    if (isOpen) {
      // Set a timeout to trigger the exit animation
      setTimeout(() => setExit(true), 5000);

      // Set a timeout to close the snackbar after the animation
      setTimeout(() => {
        setIsOpen(false);
        setExit(false);
      }, 5000);
    }
  }, [isOpen, setIsOpen]);

  // Return null if the snackbar shouldn't be open
  if (!isOpen) {
    return null;
  }

  // Determine the message to display based on cartResponse
  let message = "has been added to the cart";
  if (cartResponse === "Already Same Data Exists") {
    message = "is already in the cart";
  }

  // JSX for the Snackbar component
  return (
    <>
      <div
        className={`fixed z-50 left-0 lg:left-20 bottom-20 w-96 bg-white p-4 rounded shadow-lg flex justify-between transition-transform duration-200 transform-gpu ${
          exit ? 'translate-y-full' : 'translate-y-0'
        }`}
      >
        <div className="flex items-center">
        <div className="w-20 h-20 relative">
  <img
     src={setImageSrc(product.image || product.image_url, product.name || product.product_name)}
    alt={product.name || product.product_name}
    className="rounded-lg"
    style={{ objectFit: 'contain', width: '100%', height: '100%' }}
  />
</div>

          <div className="ml-4 flex-grow">
          <h3 className="text-xl font-bold mb-2 overflow-hidden overflow-ellipsis whitespace-nowrap max-w-full" style={{ textOverflow: 'ellipsis' }}>
  {(product.name || product.product_name).substring(0, 20)}
  {(product.name || product.product_name).length >= 20 && '...'}
</h3>

            <p>{message}</p>
            <div className='mt-3'>
              <Link href="/Cart" className="bg-primary text-white rounded px-4 py-2 mr-2">View cart</Link>
              <Link href="/checkout/Checkout"  className="bg-primary text-white rounded px-4 py-2">Checkout</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  

  );
};
