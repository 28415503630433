import { useContext } from 'react';
import NextLink from 'next/link';
import { CurrencyContext } from "../../contexts/CurrencyContext";

const Link = ({ href, children, ...rest }) => {
  const { currentCurrency } = useContext(CurrencyContext);

  // If href is null, return null or set a default value for href
  if (href === null) {
    console.warn('Link component received a null href prop. Rendering null.');
    return null; // or you could set a default href like: href = '/';
  }

  let modifiedHref = href;

  // Check if href is an object and has a query property
  if (typeof href === 'object' && href !== null && href.query) {
    modifiedHref = {
      ...href,
      query: { ...href.query, 
        // currency: currentCurrency
       }
    };
  } else if (typeof href === 'string') {
    const url = new URL(href, typeof window !== 'undefined' ? window.location.href : 'http://localhost');
    // url.searchParams.set('currency', currentCurrency);
    modifiedHref = url.toString();
  }

  return <NextLink href={modifiedHref} {...rest}>{children}</NextLink>;
};

export default Link;
